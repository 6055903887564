import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useInstanceStore } from '@/app/stores/instance';

export const useTariffStore = defineStore('tariff', () => {
  const availableTrailLengthForSelectedCurrency = ref([]);
  const availableDaysIntervalForSelectedCurrencyAndBillingModel = ref([]);
  const selectedCurrency = ref(null);
  const billingModel = ref(null);
  const selectedTrialLength = ref(null);
  const selectedDaysInterval = ref(null);
  const subscriptionPlan = ref(null);
  const availableCurrencies = ref([]);
  const availableBillingModels = ref([]);

  const getAvailableTrialLength = () => {
    const instanceStore = useInstanceStore();
    const { instance } = storeToRefs(instanceStore);

    if (billingModel.value === 'NO_TRIAL') {
      availableTrailLengthForSelectedCurrency.value = [];
      return;
    }

    availableTrailLengthForSelectedCurrency.value = instance.value.subscriptionPlans
      .reduce((acc, currentValue) => {
        if (
          acc.indexOf(currentValue.trialLength) === -1 &&
          currentValue.currency === selectedCurrency.value &&
          currentValue.trialLength !== null
        ) {
          acc.push(currentValue.trialLength);
        }

        return acc;
      }, [])
      .sort((a, b) => a - b);
  };

  const getAvailableDaysInterval = () => {
    const instanceStore = useInstanceStore();
    const { instance } = storeToRefs(instanceStore);

    availableDaysIntervalForSelectedCurrencyAndBillingModel.value = instance.value.subscriptionPlans
      .reduce((acc, currentValue) => {
        if (
          billingModel.value === 'PAID_TRIAL' &&
          acc.indexOf(currentValue.daysInterval) === -1 &&
          currentValue.currency === selectedCurrency.value &&
          currentValue.trialLength === selectedTrialLength.value &&
          currentValue.billingModel === billingModel.value
        ) {
          acc.push(currentValue.daysInterval);
        }

        if (
          billingModel.value === 'NO_TRIAL' &&
          acc.indexOf(currentValue.daysInterval) === -1 &&
          currentValue.currency === selectedCurrency.value &&
          currentValue.billingModel === billingModel.value
        ) {
          acc.push(currentValue.daysInterval);
        }

        return acc;
      }, [])
      .sort((a, b) => a - b);
  };

  const setDefaultCurrency = () => {
    const instanceStore = useInstanceStore();
    const { instance } = storeToRefs(instanceStore);

    selectedCurrency.value = instance.value.defaultCurrency;
  };

  const setDefaultMembershipTypeForSelectedCurrency = () => {
    const instanceStore = useInstanceStore();
    const { instance } = storeToRefs(instanceStore);

    if (instance.value.length === 0) {
      return;
    }

    const obj = instance.value.subscriptionPlans.filter(e => e.currency === selectedCurrency.value);

    const isPaidTrial = obj.some(e => e.billingModel === 'PAID_TRIAL');
    billingModel.value = isPaidTrial ? 'PAID_TRIAL' : 'NO_TRIAL';
  };

  const setDefaultTrialLength = () => {
    if (billingModel.value === 'NO_TRIAL') {
      selectedTrialLength.value = null;
      return;
    }

    selectedTrialLength.value = availableTrailLengthForSelectedCurrency.value.sort((a, b) => a - b)[
      availableTrailLengthForSelectedCurrency.value.length - 1
    ];
  };

  const setDefaultDaysInterval = () => {
    selectedDaysInterval.value = availableDaysIntervalForSelectedCurrencyAndBillingModel.value.sort(
      (a, b) => a - b
    )[availableDaysIntervalForSelectedCurrencyAndBillingModel.value.length - 1];
  };

  const updateSubscriptionPlan = () => {
    const instanceStore = useInstanceStore();
    const { instance } = storeToRefs(instanceStore);

    subscriptionPlan.value = instance.value.subscriptionPlans.find(plan => {
      return (
        plan.currency === selectedCurrency.value &&
        plan.billingModel === billingModel.value &&
        plan.trialLength === selectedTrialLength.value &&
        plan.daysInterval === selectedDaysInterval.value
      );
    });
  };

  const getAvailableCurrencies = () => {
    const instanceStore = useInstanceStore();
    const { instance } = storeToRefs(instanceStore);

    availableCurrencies.value = instance.value.subscriptionPlans.reduce((acc, currentValue) => {
      if (acc.indexOf(currentValue.currency) === -1) {
        acc.push(currentValue.currency);
      }
      return acc;
    }, []);
  };

  const getBillingModels = () => {
    const instanceStore = useInstanceStore();
    const { instance } = storeToRefs(instanceStore);

    availableBillingModels.value = instance.value.subscriptionPlans.reduce((acc, currentValue) => {
      if (
        acc.indexOf(currentValue.billingModel) === -1 &&
        currentValue.currency === selectedCurrency.value
      ) {
        acc.push(currentValue.billingModel);
      }
      return acc;
    }, []);
  };

  const areTheSameBillingModel = computed(() => {
    const instanceStore = useInstanceStore();
    const { instance } = storeToRefs(instanceStore);

    if (instance.value.length === 0) {
      return false;
    }

    return instance?.value.subscriptionPlans.every(
      item => item.billingModel === billingModel.value
    );
  });

  const updateCurrency = newValue => {
    selectedCurrency.value = newValue;

    setDefaultMembershipTypeForSelectedCurrency();
    getAvailableTrialLength();
    setDefaultTrialLength();
    getAvailableDaysInterval();
    setDefaultDaysInterval();
    getBillingModels();
    updateSubscriptionPlan();
  };

  const updateBillingModel = newValue => {
    billingModel.value = newValue;
    getAvailableTrialLength();
    setDefaultTrialLength();
    getAvailableDaysInterval();
    setDefaultDaysInterval();
    updateSubscriptionPlan();
  };

  const updateTrialLength = newValue => {
    selectedTrialLength.value = Number(newValue);
    getAvailableDaysInterval();
    setDefaultDaysInterval();
    updateSubscriptionPlan();
  };

  const updateDaysInterval = newValue => {
    selectedDaysInterval.value = Number(newValue);
    updateSubscriptionPlan();
  };

  const getTariffPlansForTrailAndRecurringLength = (trialLength, recurringLength) => {
    const instanceStore = useInstanceStore();
    const { instance } = storeToRefs(instanceStore);

    return instance.value.subscriptionPlans.filter(
      e => e.trialLength === trialLength && e.daysInterval === recurringLength
    );
  };

  return {
    selectedCurrency,
    billingModel,
    setDefaultTrialLength,
    updateSubscriptionPlan,
    getAvailableCurrencies,
    getBillingModels,
    areTheSameBillingModel,
    subscriptionPlan,
    availableCurrencies,
    selectedTrialLength,
    updateCurrency,
    updateTrialLength,
    availableTrailLengthForSelectedCurrency,
    getAvailableTrialLength,
    getAvailableDaysInterval,
    getTariffPlansForTrailAndRecurringLength,
    selectedDaysInterval,
    setDefaultDaysInterval,
    updateDaysInterval,
    availableDaysIntervalForSelectedCurrencyAndBillingModel,
    availableBillingModels,
    updateBillingModel,
    setDefaultMembershipTypeForSelectedCurrency,
    setDefaultCurrency,
  };
});
