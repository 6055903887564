import { defineStore } from 'pinia';
import { ref } from 'vue';
import { fetchInstanceInfo } from '@/app/api/instance';
import { useTariffStore } from '@/app/stores/tariff';
import { alignStringBooleanTypesInObject } from '@/app/helpers/object';

export const useInstanceStore = defineStore('instance', () => {
  const instance = ref([]);
  const companyName = ref('');
  const initialPeriodName = ref('');
  const isInstanceInfoFetched = ref(false);
  const instanceFetchError = ref(false);
  const defaultCountry = ref('');
  const instanceAttributes = ref(null);

  const tariffStore = useTariffStore();

  const fetchInstance = () => {
    fetchInstanceInfo()
      .then(res => {
        instance.value = res;
        document.title = res.name;
        isInstanceInfoFetched.value = true;
        companyName.value = instance.value.companyName.split('').reverse().join('');
        initialPeriodName.value = instance.value.initialPeriodName;
        defaultCountry.value = instance.value.defaultCountry;
        instanceAttributes.value = alignStringBooleanTypesInObject(
          instance.value.attributes
        );

        tariffStore.setDefaultCurrency();
        tariffStore.setDefaultMembershipTypeForSelectedCurrency();
        tariffStore.getAvailableTrialLength();
        tariffStore.setDefaultTrialLength();
        tariffStore.getAvailableDaysInterval();
        tariffStore.setDefaultDaysInterval();
        tariffStore.updateSubscriptionPlan();
        tariffStore.getBillingModels();
        tariffStore.getAvailableCurrencies();
      })
      .catch(() => {
        instanceFetchError.value = true;
      });
  };

  return {
    instance,
    isInstanceInfoFetched,
    companyName,
    initialPeriodName,
    fetchInstance,
    instanceFetchError,
    defaultCountry,
    instanceAttributes,
  };
});
