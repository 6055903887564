export const alignStringBooleanTypesInObject = object =>
  object
    ? Object.entries(object)
        .map(boolFromStringOtherwiseNoOp)
        .reduce(gatherObjectFromEntries, {})
    : null;

const boolFromStringOtherwiseNoOp = ([key, value]) => {
  if (value == 'true') return [key, true];
  if (value == 'false') return [key, false];
  return [key, value];
};

const gatherObjectFromEntries = (accumulation, [key, value]) => {
  accumulation[key] = value;
  return accumulation;
};
