<template>
  <header id="header">
    <div>
      <div class="container-fluid center-max-1320-px">
        <div
          class="row flex-sm-nowrap justify-content-between align-items-center align-self-center my-2"
        >
          <div class="col-md-6 d-flex justify-content-center justify-content-sm-start my-2">
            <router-link to="/"
              ><img class="logo" alt="logo" href="/" src="/static/images/logo.png"
            /></router-link>
          </div>
          <div class="col-md-6 d-flex justify-content-center justify-content-sm-end my-2">
            <a
              v-if="!logged"
              class="header-button"
              href="javascript:void(0);"
              @click="redirectToLogin"
              >Login</a
            >
            <router-link v-if="logged" class="header-button" to="/members-area/vpn-client"
              >Member's Area</router-link
            >
            <a v-if="logged" class="header-button" href="javascript:void(0);" @click="logout"
              >Logout</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="second-background">
      <div class="container-fluid center-max-1320-px p-3">
        <div
          class="row flex-sm-nowrap justify-content-between align-items-center align-self-center"
        >
          <div
            class="col-md-6 d-flex justify-content-center justify-content-sm-start contact-top-element text-center"
          >
            <span
              >E-mail:
              <a :href="'mailto:' + instance?.supportEmail" style="display: inline">{{
                instance?.supportEmail
              }}</a></span
            >
          </div>
          <div
            class="col-md-6 d-flex justify-content-center justify-content-sm-end contact-top-element text-center"
          >
            <span
              >Customer Support:
              <a :href="'tel:' + instance?.phoneNumber" style="display: inline">{{
                instance?.phoneNumber
              }}</a></span
            >
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { ref } from 'vue';
import { useUser } from '@/app/use/user';
import { redirectToRoute } from '@/app/router';
import { useRouter } from 'vue-router';
import { useInstanceStore } from '@/app/stores/instance';
import { storeToRefs } from 'pinia';

export default {
  name: 'PortalHeader',
  inheritAttrs: false,
  setup() {
    const { isLogged, setUserData, logout, userName } = useUser();

    const logged = ref(isLogged);
    const user = ref(userName);

    const router = useRouter();
    const vpnClientRoute = router.resolve({
      name: 'VpnClient',
    });

    const redirectToLogin = () => {
      setUserData({ requestedRoute: vpnClientRoute });
      redirectToRoute('/login');
    };

    const instanceStore = useInstanceStore();
    const { instance } = storeToRefs(instanceStore);

    return { logged, user, logout, redirectToLogin, instance };
  },
};
</script>
