import { useStore } from '@/app/use/store';
import { computed } from 'vue';
import { redirectToRoute, useUrl } from '@/app/router';
import decode from 'jwt-decode';
import { removeTokensFromLocalStorage, setTokens } from '@/app/helpers/auth';

const DEFAULT_USER_DATA = {
  isLogged: false,
  name: 'Unknown',
  requestedRoute: '/',
  roles: '',
  permissions: '',
  tokens: {},
  userId: '',
};

const { store, setData, reset } = useStore(DEFAULT_USER_DATA);

export const useUser = () => {
  const login = tokens => {
    const { sub, roles, permissions, uuid } = decode(tokens.access_token);
    const userObject = { isLogged: true, name: sub, roles, permissions, tokens, userId: uuid };
    setTokens(tokens, userObject);
    setData(userObject);
    redirectToRoute(store.value.requestedRoute);
  };

  const logout = () => {
    console.log('logout');
    removeTokensFromLocalStorage();
    reset();
    if (useUrl().currentRoute.value.meta.requiresAuth) {
      redirectToRoute('/');
    }
  };

  const isLogged = computed(() => store.value.isLogged);
  const refreshToken = computed(() => store.value.refreshToken);
  const accessToken = computed(() => store.value.accessToken);
  const requestedRoute = computed(() => store.value.requestedRoute);
  const userName = computed(() => store.value.name);
  const userId = computed(() => store.value.userId);

  return {
    user: store,
    isLogged,
    refreshToken,
    accessToken,
    requestedRoute,
    setUserData: setData,
    clearUserData: reset,
    login,
    logout,
    userName,
    userId,
  };
};
