export const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: 'Home',
    },
    component: () => import(/* webpackChunkName: "Home" */ '@/views/home/Home.vue'),
  },
  {
    path: '/payment',
    name: 'Payment',
    meta: {
      title: 'Payment'
    },
    component: () => import(/* webpackChunkName: "Payment" */ '@/views/signup/Payment.vue'),
  },
  {
    path: '/success',
    name: 'Success',
    meta: {
      title: 'Payment Success'
    },
    component: () => import(/* webpackChunkName: "SuccessfulPayment" */ '@/views/signup/SuccessfulPayment.vue'),
  },
  {
    path: '/failure',
    name: 'Failure',
    meta: {
      title: 'Payment Failure'
    },
    component: () => import(/* webpackChunkName: "FailedPayment" */ '@/views/signup/FailedPayment.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: 'Login',
    },
    component: () => import(/* webpackChunkName: "Login" */ '@/views/login/Login.vue'),
  },
  {
    path: '/members-area/vpn-client',
    name: 'VpnClient',
    meta: {
      title: 'VPN Client',
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "VpnClient" */ '@/views/member-area/VpnClient.vue'),
  },
  {
    path: '/members-area/cancel',
    name: 'Cancel',
    meta: {
      title: 'Cancel Subscription',
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "CancelSubscription" */ '@/views/member-area/CancelSubscription.vue'),
  },
  {
    path: '/members-area/password-change',
    name: 'Password Change',
    meta: {
      title: 'Password Change',
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "ChangePassword" */ '@/views/member-area/ChangePassword.vue'),
  },
  {
    path: '/privacy',
    name: 'PrivacyPolicy',
    meta: {
      title: 'Privacy Policy',
    },
    component: () => import(/* webpackChunkName: "PrivacyPolicy" */ '@/views/privacy/PrivacyPolicy.vue'),
  },
  {
    path: '/terms',
    name: 'TermsAndConditions',
    meta: {
      title: 'Terms & Conditions',
    },
    component: () => import(/* webpackChunkName: "TermsAndConditions" */ '@/views/terms/TermsAndConditions.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: {
      title: 'Contact',
    },
    component: () => import(/* webpackChunkName: "Contact" */ '@/views/contact/Contact.vue'),
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'Home' },
  },
];
