<template>
  <link :href="instance?.fontsUrl" rel="stylesheet" />
  <PortalHeader v-if="isInstanceInfoFetched" />
  <router-view v-if="isInstanceInfoFetched" />

  <PortalFooter v-if="isInstanceInfoFetched" />

  <div v-if="instanceFetchError" class="fetch-error-info">
    <h1>Website is under maintenance at the moment</h1>
    <h2>Please refresh page in few minutes</h2>
  </div>
</template>

<script>
import PortalHeader from '@/app/components/PortalHeader';
import PortalFooter from '@/app/components/PortalFooter';
import { useInstanceStore } from '@/app/stores/instance';
import { onBeforeMount } from 'vue';
import { storeToRefs } from 'pinia';

export default {
  components: { PortalHeader, PortalFooter },
  inheritAttrs: false,
  setup() {
    const instanceStore = useInstanceStore();
    const { instanceFetchError, isInstanceInfoFetched, instance } = storeToRefs(instanceStore);

    onBeforeMount(() => {
      instanceStore.fetchInstance();
    });

    return { instanceFetchError, isInstanceInfoFetched, instance };
  },
};
</script>
